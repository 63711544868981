<template>
    <div>
        <div class="modal modal-right fade" id="quick_user_toggle" tabindex="-1">
            <div class="modal-dialog">
              <div class="modal-content slim-scroll3">
                <div class="modal-body p-30 bg-white">
                  <div class="d-flex align-items-center justify-content-between pb-30">
                      <h4 class="m-0">User Profile</h4>
                      <a href="#" class="btn btn-icon btn-danger-light btn-sm no-shadow" data-bs-dismiss="modal">
                          <span class="fa fa-close"></span>
                      </a>
                  </div>
                  <div>
                      <div class="d-flex flex-row">
                          <div class="">
                            <img v-if="!User || !User.candidate || !User.candidate.profile_picture || User.candidate.profile_picture === 'undefined' || User.candidate.profile_picture === ''" src="/images/avatar/avatar-13.png" alt="user" class="rounded bg-danger-light w-150" width="100">
                            <img v-else :src="(User.candidate.profile_picture)" alt="user" class="rounded bg-danger-light w-150" width="100">
                          </div>
                          <div class="ps-20">
                              <h5 class="mb-0">{{ User ? User.name : '' }}</h5>
                              <p class="my-5 text-fade">Candidate</p>
                              <a href="#"><span class="icon-Mail-notification me-5 text-success"><i class="fa-solid fa-envelope"></i></span> {{ User ? User.email :'' }}</a>
                          </div>
                      </div>
                  </div>
                    <div class="dropdown-divider my-30"></div>
                    <div>
                      <div class="d-flex align-items-center mb-30">
                          <div class="me-15 bg-primary-light h-50 w-50 l-h-60 rounded text-center">
                                <span class="icon-Library fs-24"><i class="fa-solid fa-user"></i></span>
                          </div>
                          <div class="d-flex flex-column fw-500">
                            <router-link  to="/c/edit-profile" class="text-dark hover-primary mb-1 fs-16">
                              Edit Profile
                            </router-link >
                              <!-- <span class="text-fade">Account settings and more</span> -->
                          </div>
                      </div>
                      <div class="d-flex align-items-center mb-30">
                        <div class="me-15 bg-primary-light h-50 w-50 l-h-60 rounded text-center">
                          <span class="icon-Library fs-24">  <i class="fa-solid fa-right-from-bracket"></i></span>
                       </div>
                          <div class="flex-grow-1 fw-500">
                              <a class="d-flex align-items-center text-dark hover-primary mb-1 fs-16 cursor" @click="logout()">
                                <span class="me-3">Logout</span>
                                <Loader v-if="loader" />
                              </a>
                          </div>
                      </div>
                </div>
              </div>
            </div>
        </div>
        </div>
    </div>
  </template>
  
  <script>
  import Loader from "../loader.vue";

  export default {
    name: 'rightSidebarPage',
    components: {
    Loader
  },
    props: {
        msg: String
    },
    data() {
      return {
        loader: false
      }
    },
    computed:{
      User(){
        return this.$store.state.auth.user;
      }
    },
    methods: {
      logout(){
        this.loader = true;
        this.$store.dispatch('logout',{
          userToken: localStorage.getItem('token'),
          name: 'Candidate',
        })
      }
    },
}
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>

  </style>
  