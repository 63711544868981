// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from '../../apis/candidate';
// import router from "../router/routes";

const state = {
    // Additional state if needed
    loadingValue: false,
    applicationFormDetails: '',
    resumeResponse: [],

    
}

const mutations = {
    SET_APPLICATION_FORM_DETAILS(state, data) {
       state.applicationFormDetails = data
    },
    SET_RESUME_RESPONSE_DETAILS(state, data) {
      console.log('SET_RESUME_RESPONSE_DETAILS',data);
       state.resumeResponse = data
    },

    UPDATE_LOADING_STATE(state, value) {
        state.loadingValue = value;
    },
}

const actions = {
    // Additional actions if needed
    applicationProfile({ dispatch, commit }, payload) {
      const user = JSON.parse(localStorage.getItem("user"))
        var formData = new FormData();
        formData.append("name", payload.name);
        formData.append("other_profile_picture", payload.other_profile_picture);
        formData.append("profile_picture", payload.profile_picture);
        formData.append("email", payload.email);
        formData.append("contact_number", payload.contact_number);
        // formData.append("contact_number", payload.contact_number);
        formData.append("current_location", JSON.stringify(payload.current_location));
        formData.append("dob", payload.dob);
        formData.append("gender", payload.gender);
        formData.append("race", payload.race);
        formData.append("relocate", 'no data');
        formData.append("experience", JSON.stringify(payload.experience));
        formData.append("education", JSON.stringify(payload.education));
        formData.append("skills", JSON.stringify(payload.skills));
        formData.append("career_objectives", JSON.stringify(payload.career_objectives));
        formData.append("professional_portfolio", payload.professional_portfolio);
        formData.append("resume", payload.resume);
        formData.append("languages_spoken", payload.languages_spoken);
        formData.append("other_resume", payload.resumePdf);

        return new Promise((resolve, reject) => {
          axios
            .post(axios.defaults.baseURL + apis.POST_UPDATE_PROFILE_BY_ID + user.id, formData, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then((res) => {
              console.log("applicationProfile res", res);
              dispatch("checkErrorAndSendToast", [
                res,
                "Success",
              ]);
              resolve(res)
              dispatch('getUser')
      
            })
            .catch((err) => {
              console.log("applicationProfile err", err);
              reject(err)
            });
        })
      },

      getApplicationForm({ commit }, payload) {
        console.log('payload--',payload);
        const user = JSON.parse(localStorage.getItem("user"))
            // return
            return new Promise((resolve, reject) => {
                axios
                    .get(axios.defaults.baseURL + apis.GET_PROFILE_BY_ID + user.id, {
                        headers: {
                          Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                      })
                    .then((res) => {
                        console.log('getApplicationForm res', res)
                        commit('SET_APPLICATION_FORM_DETAILS', res.data.data[0])
                        // commit("UPDATE_LOADING_STATE", false);
                    })
                    .catch((err) => {
                        console.log('getApplicationForm err', err)
                        // commit("UPDATE_LOADING_STATE", false);
                    })
            })
    },
    uploadResume({ commit }, payload) {
      var formData = new FormData();
      formData.append("resume", payload.resume);

      return new Promise((resolve, reject) => {
        axios
          .post(axios.defaults.baseURL + apis.POST_UPLOAD_RESUME + payload.user.id, formData, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((res) => {
            console.log("uploadResume- -res", res);
            // console.log("JSON.parse(res.data.data)- -res", JSON.parse(res.data.data));
            // console.log("JSON.stringify(res.data.data)- -res", JSON.stringify(res.data.data));
          
            commit('SET_RESUME_RESPONSE_DETAILS', res.data.data)
            resolve(res.data.data)
    
          })
          .catch((err) => {
            console.log("uploadResume- - err", err);
            reject(err)
      })
        });
    },

    fill_responsibilites_AI({dispatch, commit }, payload) {
      const user = JSON.parse(localStorage.getItem("user"))
        var formData = new FormData();
        formData.append("job_title", payload.job_title);

      return new Promise((resolve, reject) => {
        axios
          .post(axios.defaults.baseURL + apis.GENERATE_RESPONSIBILITIES, formData, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((res) => {
            console.log("fill_responsibilites_AI res", res);
            resolve(res.data.data)
          })
          .catch((err) => {
            console.log("fill_responsibilites_AI err", err);
            reject(err)
          });
        })
      },

}

const getters = {
    // Additional getters if needed
}

export default {
    state,
    mutations,
    actions,
    getters,
  };