// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from '../../apis/candidate';
// import router from "../router/routes";

const state = {
    // Additional state if needed
    loadingValue: false,
    applyedJobs: '',
    allJobs: [],
    appliedJobIds: []
    
}

const mutations = {
    SET_APPLYED_JOBS_DETAILS(state, data) {
        console.log('SET_APPLYED_JOBS_DETAILS set--',data);
        // if(data.image == null || data.image == 'null'){
        //     data.image = '../../assets/img/icon.png'
        // }
        state.applyedJobs = data
    },

    SET_APPLIED_JOB_IDS(state, jobIds) {
      state.appliedJobIds = jobIds;
    },

    SET_JOBS_DETAILS(state, data) {
        state.allJobs = data
    },

    // SET_USER_DETAILS_BY_ID(state, data) {
    //     state.user = data
    // },
    LOGOUT_USER(state, data) {
        state.user = data
        state.token = data
    },

    UPDATE_LOADING_STATE(state, value) {
        state.loadingValue = value;
    },
}

const actions = {
    // Additional actions if needed
    getApplyedJobs({ commit }, payload) {
        console.log('payload--',payload);
            // return
            return new Promise((resolve, reject) => {
                axios
                    .get(axios.defaults.baseURL + apis.GET_APPLYED_JOBS + payload.id, {
                        headers: {
                          Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                      })
                    .then((res) => {
                        console.log('getApplyedJobs---', res)
                        commit('SET_APPLYED_JOBS_DETAILS', res.data.data)

                        const jobIds = res.data.data.map(job => job.job_id);
                        commit('SET_APPLIED_JOB_IDS', jobIds);

                        // commit("UPDATE_LOADING_STATE", false);
                    })
                    .catch((err) => {
                        console.log('login err', err)
                        commit("UPDATE_LOADING_STATE", false);
                    })
            })
    },

    applyJob({dispatch, commit }, payload) {
        var formData = new FormData();
        formData.append("candidate_id", payload.candidate_id);
        formData.append("job_id", payload.job_id);
        formData.append("cover_letter", payload.cover_letter);

        axios
          .post(axios.defaults.baseURL + apis.POST_APPLY_JOBS, formData, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((res) => {
            console.log("applyJob res", res);
            dispatch("checkErrorAndSendToast", [
              res,
              "Success",
            ]);
    
          })
          .catch((err) => {
            console.log("applyJob err", err);
          });
      },

      getJobs({ commit }, payload) {
        // const user = JSON.parse(localStorage.getItem("user"))
            return new Promise((resolve, reject) => {
                axios
                    .get(axios.defaults.baseURL + apis.GET_JOBS, {
                        headers: {
                          Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                      })
                    .then((res) => {
                        console.log('getJobs res', res)
                        if(res.data.data != undefined){
                          commit('SET_JOBS_DETAILS', res.data.data)
                        }else{
                          commit('SET_JOBS_DETAILS', res.data.message)
                        }
                    })
                    .catch((err) => {
                        console.log('getJobs err', err)
                    })
            })
    },
}

const getters = {
    // Additional getters if needed
}

export default {
    state,
    mutations,
    actions,
    getters,
  };