// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from '../../apis/admin';
// import router from "../router/routes";

const state = {
    // Additional state if needed
    jobsData: [],
}

const mutations = {
    SET_JOBS_DATA(state, data) {
      state.jobsData = data
  },
}

const actions = {
    // Additional actions if needed
      getAdminJobs({ commit }, id) {
            // return
            return new Promise((resolve, reject) => {
                axios
                    .get(axios.defaults.baseURL + apis.GET_JOBS, {
                        headers: {
                          Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                      })
                    .then((res) => {
                        console.log('getAdminJobs---', res)
                        commit('SET_JOBS_DATA', res.data.data)
                    })
                    .catch((err) => {
                        console.log('getAdminJobs err', err)
                    })
            })
    },

}

const getters = {
    // Additional getters if needed
}

export default {
    state,
    mutations,
    actions,
    getters,
  };