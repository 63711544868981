// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from '../../apis/admin';
// import router from "../router/routes";

const state = {
    // Additional state if needed
    loadingValue: false,
    companiesData: [],
}

const mutations = {
    UPDATE_LOADING_STATE(state, value) {
        state.loadingValue = value;
    },

    SET_COMPANIES_DATA(state, data) {
      state.companiesData = data
  },
}

const actions = {
    // Additional actions if needed
      getCompanies({ commit }, id) {
            // return
            return new Promise((resolve, reject) => {
                axios
                    .get(axios.defaults.baseURL + apis.GET_COMPANIES, {
                        headers: {
                          Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                      })
                    .then((res) => {
                        console.log('getCompanies---', res)
                        commit('SET_COMPANIES_DATA', res.data.data)
                    })
                    .catch((err) => {
                        console.log('getCompanies err', err)
                    })
            })
    },

}

const getters = {
    // Additional getters if needed
}

export default {
    state,
    mutations,
    actions,
    getters,
  };