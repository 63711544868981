// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from '../../apis/company';
// import router from "../router/routes";

const state = {
    // Additional state if needed
    candidateRecommendations: [],
}

const mutations = {

    SET_RECOMMENDATION_DETAILS(state, data) {
      state.candidateRecommendations = data
  },

}

const actions = {
    // Additional actions if needed

      getCandidateRecommendations({ commit }, payload) {
            // return
            return new Promise((resolve, reject) => {
                axios
                    .get(axios.defaults.baseURL + apis.GET_RECOMMENDATION_BY_JOB_ID + payload, {
                        headers: {
                          Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                      })
                    .then((res) => {
                        console.log('getCandidateRecommendations res', res)
                        if(res.data){
                            commit('SET_RECOMMENDATION_DETAILS', res.data)
                        }
                        if('list_of_candidates' in res.data.data && res.data.data.list_of_candidates.length){
                            commit('SET_RECOMMENDATION_DETAILS', res.data.data.list_of_candidates)
                        }
                        if('candidates' in res.data.data && res.data.data.candidates.length){
                            commit('SET_RECOMMENDATION_DETAILS', res.data.data.candidates)
                        }
                        if(res.data.data == null){
                            commit('SET_RECOMMENDATION_DETAILS', res.data.data)
                        }
                     
                    })
                    .catch((err) => {
                        console.log('getCandidateRecommendations err', err)
                    })
            })
    },

      getInterestedCandidate({dispatch, commit }, payload) {
            // return
            return new Promise((resolve, reject) => {
                axios
                    .get(axios.defaults.baseURL + apis.INTERESTED_IN_CANDIDATE + payload.id, {
                        headers: {
                          Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                      })
                    .then((res) => {
                        console.log('getInterestedCandidate res', res)
                        resolve(res)
                        dispatch("checkErrorAndSendToast", [
                            res,
                            "success",
                        ]);
                        dispatch('getCandidateRecommendations', payload.jobId)
                    })
                    .catch((err) => {
                        console.log('getInterestedCandidate err', err)
                        reject(err)
                        dispatch("checkErrorAndSendToast", [
                            err.response,
                            "error",
                        ]);
                    })
            })
    },

      getNotInterestedCandidate({dispatch, commit }, payload) {
            // return
            return new Promise((resolve, reject) => {
                axios
                    .get(axios.defaults.baseURL + apis.NOT_INTERESTED_IN_CANDIDATE + payload.id, {
                        headers: {
                          Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                      })
                    .then((res) => {
                        console.log('getNotInterestedCandidate res', res)
                        resolve(res)
                        dispatch("checkErrorAndSendToast", [
                            res,
                            "success",
                        ]);
                        dispatch('getCandidateRecommendations', payload.jobId)
                    })
                    .catch((err) => {
                        console.log('getNotInterestedCandidate err', err)
                        reject(err)
                        dispatch("checkErrorAndSendToast", [
                            err.response,
                            "error",
                        ]);
                    })
            })
    },

      hireCandidate({dispatch, commit }, payload) {
            // return
            return new Promise((resolve, reject) => {
                axios
                    .get(axios.defaults.baseURL + apis.HIRE_CANDIDATE + payload.id, {
                        headers: {
                          Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                      })
                    .then((res) => {
                        console.log('hireCandidate res', res)
                        resolve(res)
                        dispatch("checkErrorAndSendToast", [
                            res,
                            "success",
                        ]);
                        dispatch('getCandidateRecommendations', payload.jobId)
                    })
                    .catch((err) => {
                        console.log('hireCandidate err', err)
                        reject(err)
                        dispatch("checkErrorAndSendToast", [
                            err.response,
                            "error",
                        ]);
                    })
            })
    },

    addNote({dispatch, commit }, payload) {
        // const user = JSON.parse(localStorage.getItem("user"))
          var formData = new FormData();
          formData.append("candidate_id", payload.candidate_id);
          formData.append("title", payload.title);
          formData.append("note", payload.note);
  
        return new Promise((resolve, reject) => {
          axios
            .post(axios.defaults.baseURL + apis.ADD_NOTE, formData, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then((res) => {
              console.log("addNote res", res);
              resolve(res)
              dispatch("checkErrorAndSendToast", [
                res,
                "success",
             ]);
             dispatch('getCandidateRecommendations', payload.jobId)
      
            })
            .catch((err) => {
              console.log("addNote err", err);
              reject(err)
              dispatch("checkErrorAndSendToast", [
                err.response,
                "error",
              ]);
            });
          })
        },

}

const getters = {
    // Additional getters if needed
}

export default {
    state,
    mutations,
    actions,
    getters,
  };