// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from '../../apis/company';
import router from "../../../router/routes";

const state = {
    // Additional state if needed
    loadingValue: false,
    postedJobs: [],
    postedJobsByJobId: '',
}

const mutations = {
    UPDATE_LOADING_STATE(state, value) {
        state.loadingValue = value;
    },

    SET_POSTED_JOBS_DETAILS(state, data) {
      state.postedJobs = data
  },
    SET_POSTED_JOBS_BY_JOBS_ID_DETAILS(state, data) {
      state.postedJobsByJobId = data
  },
}

const actions = {
    // Additional actions if needed
    postJob({dispatch, commit }, payload) {
      const user = JSON.parse(localStorage.getItem("user"))
      console.log("payloadd", payload)
      // return
        var formData = new FormData();
        formData.append("title", payload.title);
        formData.append("job_type", payload.job_type);
        formData.append("job_location", payload.job_location);
        formData.append("country", payload.country);
        formData.append("state", payload.state);
        formData.append("city", payload.city);
        formData.append("outsourcing_cost", payload.outsourcing_cost);
        formData.append("salary_range", payload.salary_range);
        formData.append("minimum_salary", payload.minimum_salary);
        formData.append("maximum_salary", payload.maximum_salary);
        formData.append("company_name", payload.company_name);
        formData.append("company_size", payload.company_size);
        formData.append("industry", payload.industry);
        formData.append("role_overview", payload.role_overview);
        formData.append("job_description", payload.job_description);
        formData.append("key_responsibilities", payload.key_responsibilities);
        formData.append("expected_outcomes", payload.expected_outcomes);
        formData.append("payrange_minimum", payload.payrange_minimum);
        formData.append("payrange_maximum", payload.payrange_maximum);
        formData.append("education", payload.education);
        formData.append("years_of_experience", payload.years_of_experience);
        formData.append("previous_experience", payload.previous_experience);
        formData.append("software_proficiency", payload.software_proficiency);
        formData.append("benefits", payload.benefits);
        formData.append("company_culture", payload.company_culture);
        formData.append("company_image", payload.company_image);
        formData.append("screening_questions", JSON.stringify(payload.screening_questions));
        formData.append("hm_id", user.id);

      return new Promise((resolve, reject) => {
        axios
          .post(axios.defaults.baseURL + apis.POST_JOB, formData, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((res) => {
            console.log("postJob res", res);
            resolve(res)
            dispatch("checkErrorAndSendToast", [
              res,
              "success",
            ]);
            router.push('/h/jobs-page'); 
    
          })
          .catch((err) => {
            console.log("postJob err", err);
            reject(err)
            dispatch("checkErrorAndSendToast", [
              err.response,
              "error",
            ]);
            
          });
        })
      },


      fill_job_details_AI({dispatch, commit }, payload) {
        const user = JSON.parse(localStorage.getItem("user"))
          var formData = new FormData();
          formData.append("industry", payload.industry);
          formData.append("title", payload.title);
          formData.append("job_type", payload.job_type);
          formData.append("job_location", payload.job_location);
          formData.append("state", payload.state);
          formData.append("country", payload.country);
          formData.append("state", payload.state);
          formData.append("city", payload.city);
  
        return new Promise((resolve, reject) => {
          axios
            .post(axios.defaults.baseURL + apis.FILL_JOB_DETAILS, formData, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then((res) => {
              console.log("fill_job_details_AI res", res);
              resolve(res.data.data)
            })
            .catch((err) => {
              console.log("fill_job_details_AI err", err);
              reject(err)
            });
          })
        },


      getPostedJobs({ commit }, payload) {
            // returnrrrrr
            const user = JSON.parse(localStorage.getItem("user"))
            return new Promise((resolve, reject) => {
                axios
                    .get(axios.defaults.baseURL + apis.GET_POSTED_JOBS_BY_USER_ID + user.id, {
                        headers: {
                          Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                      })
                    .then((res) => {
                        console.log('getPostedJobs---', res)
                        if(res.data.data != undefined){
                          commit('SET_POSTED_JOBS_DETAILS', res.data.data)
                        }else{
                          commit('SET_POSTED_JOBS_DETAILS', res.data.message)
                        }
                    })
                    .catch((err) => {
                        console.log('getPostedJobs err', err)
                    })
            })
    },

      getPostedJobsById({ commit }, id) {
            // return
            return new Promise((resolve, reject) => {
                axios
                    .get(axios.defaults.baseURL + apis.GET_POSTED_JOBS_BY_JOB_ID + id, {
                        headers: {
                          Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                      })
                    .then((res) => {
                        console.log('getPostedJobsById---', res)
                        commit('SET_POSTED_JOBS_BY_JOBS_ID_DETAILS', res.data.data[0])
                    })
                    .catch((err) => {
                        console.log('getPostedJobsById err', err)
                    })
            })
    },

      closeJob({dispatch, commit }, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get(axios.defaults.baseURL + apis.CLOSE_JOB + id, {
                        headers: {
                          Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                      })
                    .then((res) => {
                        console.log('closeJob res', res)
                        resolve(res)
                        dispatch("checkErrorAndSendToast", [
                          res,
                          "success",
                        ]);
                        router.push('/h/jobs-page'); // Use the router instance to navigate
                    })
                    .catch((err) => {
                        console.log('closeJob err', err)
                        reject(err)
                        dispatch("checkErrorAndSendToast", [
                          err.response,
                          "error",
                        ]);
                    })
            })
    },


      deleteJob({dispatch, commit }, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get(axios.defaults.baseURL + apis.DELETE_JOB + id, {
                        headers: {
                          Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                      })
                    .then((res) => {
                        console.log('deleteJob res', res)
                        resolve(res)
                        dispatch("checkErrorAndSendToast", [
                          res,
                          "success",
                        ]);
                        router.push('/h/jobs-page'); // Use the router instance to navigate
                    })
                    .catch((err) => {
                        console.log('deleteJob err', err)
                        reject(err)
                        dispatch("checkErrorAndSendToast", [
                          err.response,
                          "error",
                        ]);
                    })
            })
    },

      outsourceTheJob({dispatch, commit }, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get(axios.defaults.baseURL + apis.GET_OUTSOURCE_JOB + id, {
                        headers: {
                          Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                      })
                    .then((res) => {
                        console.log('outsourceTheJob res', res)
                        resolve(res)
                        dispatch("checkErrorAndSendToast", [
                          res,
                          "success",
                        ]);
                    })
                    .catch((err) => {
                        console.log('outsourceTheJob err', err)
                        reject(err)
                        dispatch("checkErrorAndSendToast", [
                          err.response,
                          "error",
                        ]);
                    })
            })
    },

}

const getters = {
    // Additional getters if needed
}

export default {
    state,
    mutations,
    actions,
    getters,
  };