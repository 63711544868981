<template>
    <div>
        <footer class="main-footer">
            &copy;
            2024 <a class="text-primary" href="#" target="_blank">AI Recruit</a>. All Rights Reserved.
          </footer>
    </div>
  </template>
  
  <script>
  export default {
    name: 'footerPage',
    props: {
      msg: String
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>

  </style>
  