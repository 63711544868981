// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from '../../apis/company';
import router from "../../../router/routes";

const state = {
    // Additional state if needed
    loadingValue: false,
    profileDetails: "",
    
}

const mutations = {
    UPDATE_LOADING_STATE(state, value) {
        state.loadingValue = value;
    },
    
    SET_PROFILE_DETAILS(state, data) {
        state.profileDetails = data;
    },
}

const actions = {
    // Additional actions if needed
    editCompanyProfile({dispatch, commit }, payload) {
      const user = JSON.parse(localStorage.getItem("user"))
        var formData = new FormData();
        formData.append("company_image", payload.company_image);
        formData.append("other_company_image", payload.other_company_image);
        formData.append("profile_picture", payload.profile_picture);
        formData.append("other_profile_picture", payload.other_profile_picture);
        formData.append("name", payload.name);
        formData.append("company_name", payload.company_name);
        formData.append("contact", payload.contact);
        formData.append("job_location", payload.job_location);
        formData.append("country", payload.country);
        formData.append("state", payload.state);
        formData.append("city", payload.city);
        formData.append("industry", payload.industry);
        formData.append("email", payload.email);
        formData.append("company_size", payload.company_size);
        formData.append("sector", payload.sector);
        formData.append("related_industry", payload.related_industry);
        formData.append("about_company", payload.about_company);
        formData.append("about", payload.about);
        formData.append("hm_id", user.id);

      return new Promise((resolve, reject) => {
        axios
          .post(axios.defaults.baseURL + apis.POST_EDIT_PROFILE, formData, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((res) => {
            console.log("editCompanyProfile res", res);
            resolve(res)
            dispatch("checkErrorAndSendToast", [
              res,
              "success",
           ]);
           router.push('/h'); // Use the router instance to navigate
    
          })
          .catch((err) => {
            console.log("editCompanyProfile err", err);
            reject(err)
            dispatch("checkErrorAndSendToast", [
              err.response,
              "error",
            ]);
          });
        })
      },

      getProfile({ commit }, payload) {
        // return
        const user = JSON.parse(localStorage.getItem("user"))
        return new Promise((resolve, reject) => {
            axios
                .get(axios.defaults.baseURL + apis.GET_EDIT_PROFILE + user.id, {
                    headers: {
                      Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                  })
                .then((res) => {
                    console.log('get-Profile res', res)
                    commit('SET_PROFILE_DETAILS', res.data.data[0])
                })
                .catch((err) => {
                    console.log('get-Profile err', err)
                })
        })
},

}

const getters = {
    // Additional getters if needed
}

export default {
    state,
    mutations,
    actions,
    getters,
  };