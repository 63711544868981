import router from "../router/routes";


// Common APIs
const state = {
    errorAndSuccessMessage: "",
    toasts: [],
};

const mutations = {
    CHECK_ERROR_AND_SUCCESS_MESSAGE(state, Message) {
        state.errorAndSuccessMessage = Message;
      },                                
  ADD_TOAST(state, toast) {
    state.toasts.push(toast);
    // console.log('ADD_TOAST-',state,toast)
  },
  CLEAR_TOAST(state, title) {
    // console.log('CLEAR_TOAST-',state,title)
    setTimeout(() => {
      if (title != undefined) {
        const index = state.toasts.findIndex((toast) => toast.title === title); // find toast
        state.toasts.splice(index, 1); // remove toast from array
      } else {
        state.toasts.splice(0, 1); // remove toast from array
      }
    }, 5000);
  },
  clearToast2(state, title) {
    if (title != undefined) {
      const index = state.toasts.findIndex((toast) => toast.title === title); // find toast
      state.toasts.splice(index, 1); // remove toast from array
    } else {
      state.toasts.splice(0, 1); // remove toast from array
    }
  },
};

const actions = {
    checkErrorAndSendToast({ commit }, response) {
        commit("CHECK_ERROR_AND_SUCCESS_MESSAGE", response[0]);
        console.log("message-1-", response);
        // console.log("check-1-", response[1]);
        const message = response[0];
  
        if (response[1] == "error") {
          if (message == undefined) {
            commit("ADD_TOAST", {
              title: "Server Error",
              type: "error_1",
              message:
                "May be server are not working, please try again after few moment",
            });
            commit(
              "CLEAR_TOAST",
              "May be server are not working, please try again after few moment"
            );
            return;
          } else if (message.statusText == "Unauthorized") {
            if('error' in message.data){
              commit("ADD_TOAST", {
                title: "Faild",
                type: "error_1",
                message: message.data.error,
              });
              commit("CLEAR_TOAST", message.data.error);
              localStorage.removeItem("token");
              localStorage.removeItem("user");
              alert("Your login session has been expired. 22");
              // router.go();
            }
            else{
              const errorData = message.data.error;
  
            Object.keys(errorData).forEach((key) => {
              const errorArray = errorData[key];
              if (Array.isArray(errorArray)) {
                commit("ADD_TOAST", {
                  title: "Faild",
                  type: "error_1",
                  message: `${key} error :- ` + errorArray[0] + ", type again",
                });
                // setTimeout(() => {
                commit(
                  "CLEAR_TOAST",
                  `${key} error :- ` + errorArray[0] + ", type again"
                );
                // }, 10000);
                console.log(`${key} Errors:`, errorArray);
              }
            });
            }
          } else if (message.statusText == "Unauthenticated") {
            // commit("ADD_TOAST", {
            //   title: "Faild",
            //   type: "error_1",
            //   message: message.data.message,
            // });
            // commit("CLEAR_TOAST", message.data.message);
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            alert("Your login session has been expired. 33");
            // router.go();
          } else if (message.status === 429) {
            commit("ADD_TOAST", {
              title: "Faild",
              type: "error_1",
              message: message.statusText + ", try few moment later",
            });
            commit("CLEAR_TOAST", message.statusText + ", try few moment later");
            return;
          } else if (message.status === 500) {
            commit("ADD_TOAST", {
              title: "Faild",
              type: "error_1",
              message: message.statusText + ", try few moment later",
            });
            commit("CLEAR_TOAST", message.statusText + ", try few moment later");
            router.push("*");
            return;
          }
          // commit("ADD_TOAST", {
          //   title: "Faild",
          //   type: "error_1",
          //   message: message.data.message,
          // });
          // setTimeout(() => {
          //   commit("CLEAR_TOAST", message.data.message);
          // }, 5000);
          else if (
            (message.data.message == "Error validation" ||
              message.data.message == "Error Validation") &&
            message.data.data
          ) {
            const errorData = message.data.data;
  
            Object.keys(errorData).forEach((key) => {
              const errorArray = errorData[key];
              if (Array.isArray(errorArray)) {
                commit("ADD_TOAST", {
                  title: "Faild",
                  type: "error_1",
                  message: `${key} error :- ` + errorArray[0] + ", type again",
                });
                // setTimeout(() => {
                commit(
                  "CLEAR_TOAST",
                  `${key} error :- ` + errorArray[0] + ", type again"
                );
                // }, 10000);
                console.log(`${key} Errors:`, errorArray);
              }
            });
          } else {
            commit("ADD_TOAST", {
              title: "Faild",
              type: "error_1",
              message: message.data.message,
            });
            commit("CLEAR_TOAST", message.data.message);
          }
        } else {
          commit("ADD_TOAST", {
            title: "Success",
            type: "success_1",
            message: message.data.message,
          });
          commit("CLEAR_TOAST", message.data.message);
        }
      },
};

const getters = {

};

export default {
  state,
  mutations,
  actions,
  getters,
};
