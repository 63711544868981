// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from "./apis/auth";
import router from "../router/routes";

const state = {
  // Additional state if needed
  loadingValue: false,
  user: null,
  token: null,
};

const mutations = {
  SET_LOGIN_DETAILS(state, data) {
    console.log("user set", data);
    // if(data.image == null || data.image == 'null'){
    //     data.image = '../../assets/img/icon.png'
    // }
    state.user = data;
  },
  // SET_USER_DETAILS_BY_ID(state, data) {
  //     state.user = data
  // },
  LOGOUT_USER(state, data) {
    state.user = data;
    state.token = data;
  },

  UPDATE_LOADING_STATE(state, value) {
    state.loadingValue = value;
  },
};

const actions = {
  // Additional actions if needed
  login({dispatch, commit }, payload) {
    console.log("payload--", payload);
    // return
    var formData = new FormData();
    formData.append("email", payload.email);
    formData.append("password", payload.password);
    return new Promise((resolve, reject) => {
      axios
        .post(axios.defaults.baseURL + apis.POST_LOGIN, formData)
        .then((res) => {
          console.log("login res--", res);
          // commit("UPDATE_LOADING_STATE", false);
          var formData2 = new FormData();
          formData2.append("token", res.data.data.token);
          axios
            .post(
              axios.defaults.baseURL + "get_current_logged_user",
              formData2
            )
            .then((response) => {
              console.log("login details-- auth--", response);
              localStorage.setItem("token", res.data.data.token);
              // localStorage.setItem("user", JSON.stringify(response.data.data));
                const user = {
                  id: response.data.data.id,
                  name: response.data.data.name,
                  email: response.data.data.email,
                  position: response.data.data.position,
                  roles: response.data.data.roles,
                  email_verified_at: response.data.data.email_verified_at,
                  company_name: response.data.data.company_name,
                  remember_token: res.data.data.token,
                };
                commit("SET_LOGIN_DETAILS", user);
                localStorage.setItem("user", JSON.stringify(user));
                // store.state.Main_Loader_value = false;
                if (response.data.data.roles == 1) {
                  router.push("/a").then(() => {
                    router.go();
                  });
                } else if (response.data.data.roles == 2) {
                  router.push("/h").then(() => {
                    router.go();
                  });
                } else {
                  router.push("/").then(() => {
                    router.go();
                  });
                }
                // router.push("/").go()
            })
            .catch((err) => {
              console.log("error ff hai--", err);
  
              // localStorage.removeItem("token");
              // localStorage.removeItem("user");
              // router.go();
            });
        })
        .catch((err) => {
          console.log("login err", err);
          dispatch("checkErrorAndSendToast", [
            err.response,
            "error",
          ]);
          reject(err)
        });
    })
  },

  getUser({ commit }, Data) {
          var formData2 = new FormData();
          formData2.append("token", res.data.data.token);
          axios
            .post(
              axios.defaults.baseURL + "get_current_logged_user",
              formData2
            )
            .then((response) => {
              console.log("login details-- auth--", response);
              localStorage.setItem("token", res.data.data.token);
              // localStorage.setItem("user", JSON.stringify(response.data.data));
                const user = {
                  id: response.data.data.id,
                  name: response.data.data.name,
                  email: response.data.data.email,
                  position: response.data.data.position,
                  roles: response.data.data.roles,
                  email_verified_at: response.data.data.email_verified_at,
                  company_name: response.data.data.company_name,
                  remember_token: res.data.data.token,
                };
                commit("SET_LOGIN_DETAILS", user);
            })
            .catch((err) => {
              console.log("error ff hai--", err);
            });
  },

  setLogin({ commit }, Data) {
    if (process.client) {
      console.log("Data auth", Data);
      commit("SET_LOGIN_DETAILS", Data);
      commit("SET_ALL_FOLLOWING_WITH_FILTERED", Data);
    }
  },
  setUserDetailsByID({ commit }, Data) {
      console.log("Data auth", Data);
      commit("SET_LOGIN_DETAILS", Data);
  },
  
  logout({dispatch, commit }, payload) {
      const formdata = new FormData();
      formdata.append("token", payload.userToken);
      axios
        .post(axios.defaults.baseURL + apis.POST_LOGOUT, formdata,{})
        .then((res) => {
          dispatch("checkErrorAndSendToast", [
            res,
            "success",
          ]);
          localStorage.removeItem("token");
          localStorage.removeItem("user");

          if(payload.name == 'Hiring Manager'){
            router.push("/h/login").then(() => {
              router.go();
            });
          }
          else if(payload.name == 'Candidate'){
            router.push("/c/login").then(() => {
              router.go();
            });
          }
          else if(payload.name == 'admin'){
            router.push("/a/login").then(() => {
              router.go();
            });
          }
        })
        .catch((err) => {
          console.log("logout err", err);
          dispatch("checkErrorAndSendToast", [
            err.response,
            "error",
          ]);
        });
  },


  candidateRegister({dispatch, commit }, payload) {
    console.log("payload--", payload);
    // return
    var formData = new FormData();
    formData.append("name", payload.name);
    formData.append("email", payload.email);
    formData.append("dob", payload.dob);
    formData.append("gender", payload.gender);
    formData.append("race", payload.race);
    formData.append("password", payload.password);
    formData.append("confirm_password", payload.confirm_password);
    return new Promise((resolve, reject) => {
      axios
        .post(axios.defaults.baseURL + apis.POST_REDISTER_CANDIATE, formData)
        .then((res) => {
          console.log("register res", res);
          localStorage.setItem("token", res.data.data.token);
          router.push("/").then(() => {
            router.go();
          });

          resolve(res);
          dispatch("checkErrorAndSendToast", [
            res,
            "success",
          ]);
        })
        .catch((err) => {
          console.log("register err", err);
          dispatch("checkErrorAndSendToast", [
            err.response,
            "error",
          ]);
          reject(err);
        });
    })
  },

  companyRegister({dispatch, commit }, payload) {
    console.log("payload--", payload);
    // return
    var formData = new FormData();
    formData.append("name", payload.name);
    formData.append("company_name", payload.company_name);
    formData.append("position", payload.position);
    formData.append("email", payload.email);
    formData.append("password", payload.password);
    formData.append("confirm_password", payload.confirm_password);
    return new Promise((resolve, reject) => {
      axios
        .post(axios.defaults.baseURL + apis.POST_REDISTER_HIRING_MANAGAER, formData)
        .then((res) => {
          console.log("companyRegister res", res);
          localStorage.setItem("token", res.data.data.token);
          router.push("/h").then(() => {
            router.go();
          });

          resolve(res);
          dispatch("checkErrorAndSendToast", [
            res,
            "success",
          ]);
        })
        .catch((err) => {
          console.log("companyRegister err", err);
          reject(err);
        });
    })
  },


  forgotPassword({dispatch, commit }, payload) {
    var formData = new FormData();
    formData.append("email", payload.email);
    return new Promise((resolve, reject) => {
      axios
        .post(axios.defaults.baseURL + apis.FORGOT_PASSWORD, formData)
        .then((res) => {
          console.log("forgotPassword res", res);

          resolve(res);
          dispatch("checkErrorAndSendToast", [
            res,
            "success",
          ]);
        })
        .catch((err) => {
          console.log("forgotPassword err", err);
          dispatch("checkErrorAndSendToast", [
            err.response,
            "error",
          ]);
          reject(err);
        });
    })
  },

  resetPassword({dispatch, commit }, payload) {
    var formData = new FormData();
    formData.append("id", payload.id);
    formData.append("password", payload.password);
    formData.append("confirm_password", payload.confirm_password);
    return new Promise((resolve, reject) => {
      axios
        .post(axios.defaults.baseURL + apis.RESET_PASSWORD, formData)
        .then((res) => {
          console.log("resetPassword res", res);
          router.push("/").then(() => {
            router.go();
          });

          resolve(res);
          dispatch("checkErrorAndSendToast", [
            res,
            "success",
          ]);
        })
        .catch((err) => {
          console.log("resetPassword err", err);
          dispatch("checkErrorAndSendToast", [
            err.response,
            "error",
          ]);
          reject(err);
        });
    })
  },
};

const getters = {
  // Additional getters if needed
};

export default {
  state,
  mutations,
  actions,
  getters,
};
