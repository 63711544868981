export const apis = {

    //AUTH
    REGISTER_CONPANY: 'hiring_manager/register',
    LOGIN_COMPANY: 'hiring_manager/login',


    // JOB
    POST_JOB : "hiring_manager/post_job",
    POST_EDIT_JOB_BY_ID : "hiring_manager/edit_job/",
    DELETE_JOB : "hiring_manager/delete_job/",
    CLOSE_JOB : "hiring_manager/close_job/",
    GET_POSTED_JOBS_BY_USER_ID : "hiring_manager/get_posted_jobs/",
    GET_POSTED_JOBS_BY_JOB_ID : "hiring_manager/get_job/",
    GET_OUTSOURCE_JOB : "hiring_manager/outsource_job/",
    FILL_JOB_DETAILS : "hiring_manager/fill_job_details",
    
    //PROFILE
    POST_EDIT_PROFILE : "hiring_manager/edit_profile",
    GET_EDIT_PROFILE : "hiring_manager/profile/",

    //DASHBOARD
    GET_DASHBOARD_DATA_BY_ID : "hiring_manager/dashboard/",

    //Recommendation
    GET_RECOMMENDATION_BY_JOB_ID : "hiring_manager/get_recommendations/",
    INTERESTED_IN_CANDIDATE : "hiring_manager/interested/",
    NOT_INTERESTED_IN_CANDIDATE : "hiring_manager/not_interested/",
    HIRE_CANDIDATE : "hiring_manager/hire/",
    
    
    //NOTE
    ADD_NOTE : "hiring_manager/add_note",
    EDIT_NOTE : "hiring_manager/edit_note/",
    GET_NOTE_BY_ID : "hiring_manager/get_note/",


};