import { createRouter, createWebHistory } from "vue-router";
import axios from "axios";
import store from "../store";
import Home from "../views/candidate/index.vue";

// import AdminLayout from "../layouts/AdminLayout.vue";
// import PartnerLayout from "../layouts/PartnerLayout.vue";
// import CandidateLayout from "../layouts/CandidateLayout.vue";
// import UserLayout from "../layouts/UserLayout.vue";
const routes = [
  // CANDIDATE
  {
    path: "/",
    name: "HomePage",
    component: Home,
    meta: { authRequired: true, title: 'Home | AI Recruit' },
    // meta: { authRequired: false },
    // beforeEnter: (to, from, next) => {
    //   var token = localStorage.getItem("token");
    //   const user = JSON.parse(localStorage.getItem("user"));
    //   console.log('store-from-routes--',store);
    //   var isAuthenticated = Boolean(token);
    //   var isAuthenticatedUser = Boolean(user);
    //   // Middleware logic specific to this route
    //   if (isAuthenticated && isAuthenticatedUser) {
    //     // console.log('store.email',store.state.user.email);
    //     if (user.roles === 3) {
    //       if (store.state.auth.user == null || store.state.auth.user == undefined) {
    //         var formData2 = new FormData();
    //         formData2.append("token", token);
    //         axios
    //           .post(axios.defaults.baseURL + 'get_current_logged_user', formData2)
    //           .then((response) => {
    //             console.log("login details--from routes--", response);
    //             store.commit("SET_LOGIN_DETAILS", user);
    //             // if (response.data.data.email == user.email) {
    //             //   const user = {
    //             //     name: response.data.data.first_name,
    //             //     roles: response.data.data.roles,
    //             //     id: response.data.data.id,
    //             //     mobile_phone: response.data.data.mobile_phone,
    //             //     phone: response.data.data.phone,
    //             //     email: response.data.data.email,
    //             //     company: response.data.data.company,
    //             //     token: localStorage.getItem("token"),
    //             //   };
    //             //   store.commit("SET_LOGIN_DETAILS", user);
    //             //   // store.state.Main_Loader_value = false;
    //             //   next();
    //             // } else {
    //             //   alert("Your login session expired 44");
    //             //   next({ name: "Login" });
    //             // }
    //           })
    //           .catch((err) => {
    //             console.log("error hai routes--", err);
    //             if (
    //               err.response.data.message ==
    //               'Attempt to read property "tokenable" on null'
    //             ) {
    //               alert("Your login session has been expired.");
    //             }
    //             localStorage.removeItem("token");
    //             localStorage.removeItem("user");
    //             router.go();
    //             if (err.response.statusText == "Unauthorized") {
    //               router.push("/login");
    //             }
    //           });
    //       } else {
    //         if (store.state.auth.user.email == user.email) {
    //           next();
    //         } else {
    //           alert("Your login session expired");
    //           next({ name: "Login" });
    //         }
    //         //            alert('You have already logged in as a partner')
    //       }
    //       // next();
    //     } else {
    //       alert("You are not authorized");
    //       next({ name: "Login" });
    //       // window.$nuxt.$toasted.error('You are not authorized');
    //     }
    //   } else {
    //     next({ name: "Login" });
    //   }
    // },
    // children:[
    // ]
  },
  {
    path: "/c/application-form",
    name: "ApplicationForm",
    component: function () {
      return import(/* webpackChunkName: "about" */ "../views/candidate/applicationForm.vue");
    },
    meta: { authRequired: true,title: 'Application Form | AI Recruit' },
  },

  {
    path: "/c/candidate-jobs",
    name: "jobs",
    component: function () {
      return import(/* webpackChunkName: "about" */ "../views/candidate/jobs.vue");
    },
    meta: { authRequired: true,title: 'Candidate Jobs | AI Recruit' },
  },
  
  {
    path: "/c/r-application-form",
    name: "Resume Application Form",
    component: function () {
      return import(/* webpackChunkName: "about" */ "../views/candidate/resumeResponseForm.vue");
    },
    meta: { authRequired: true, title: 'Application Form | AI Recruit' },
  },
  {
    path: "/c/cover-letter-:id",
    name: "CoverPage",
    component: function () {
      return import(/* webpackChunkName: "about" */ "../views/candidate/coverLetter.vue");
    },
    meta: { authRequired: true, title: 'Cover Letter | AI Recruit' },
  },
  {
    path: "/c/edit-profile",
    name: "EditProfile",
    component: function () {
      return import(/* webpackChunkName: "about" */ "../views/candidate/editProfile.vue");
    },
    meta: { authRequired: true, title: 'Edit Profile | AI Recruit' },
  },
  {
    path: "/c/schedule-booking",
    name: "ScheduleBooking",
    component: function () {
      return import(/* webpackChunkName: "about" */ "../views/candidate/scheduleBooking.vue");
    },
    meta: { authRequired: true, title: 'Schedule Booking | AI Recruit' },
  },

  // ADMIN
  {
    path: "/a",
    name: "AdminHomePage",
    component: function () {
      return import(/* webpackChunkName: "about" */ "../views/admin/index.vue");
    },
    meta: { authRequired: true, title: 'Home | AI Recruit' },
    children: [
 
    ],
  },

  {
    path: "/a/company",
    name: "CompanyPage",
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "../views/admin/company.vue"
      );
    },
    meta: {authRequired: true, title: 'Company | AI Recruit' },
  },
  {
    path: "/a/jobs-details",
    name: "JobsDetail",
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "../views/admin/jobs.vue"
      );
    },
    meta: {authRequired: true, title: 'Jobs Details | AI Recruit' },
  },
  {
    path: "/a/outsourcing",
    name: "OutsourcingPage",
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "../views/admin/outsourcing.vue"
      );
    },
    meta: {authRequired: true, title: 'Outsourcing | AI Recruit' },
  },
  {
    path: "/a/candidates",
    name: "CandidatesPage",
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "../views/admin/candidates.vue"
      );
    },
    meta: {authRequired: true, title: 'Candidates | AI Recruit' },
  },
  {
    path: "/a/admin-billing",
    name: "Billing",
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "../views/admin/billing.vue"
      );
    },
    meta: {authRequired: true, title: 'Billing | AI Recruit' },
  },

  // COMPANY
  {
    path: "/h/dashboard",
    name: "CompanyHomePage",
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "../views/company/index.vue"
      );
    },
    meta: {authRequired: true, title: 'Home | AI Recruit' }, // Set the title for the Home route
    children: [],
  },

  {
    path: "/h",
    name: "CompnayDetailsPage",
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "../views/company/companyDetails.vue"
      );
    },
    meta: {authRequired: true, title: 'Company Details | AI Recruit' },
  },
  {
    path: "/h/edit-company-profile",
    name: "EditCompanyProfile",
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "../views/company/editProfile.vue"
      );
    },
    meta: {authRequired: true, title: 'Edit Company Profile | AI Recruit' },
  },
  {
    path: "/h/schedule",
    name: "SchedulePage",
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "../views/company/schedule.vue"
      );
    },
    meta: {authRequired: true, title: 'Schedule | AI Recruit' },
  },
  {
    path: "/h/jobs-page",
    name: "JobsPage",
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "../views/company/jobs/jobsPage.vue"
      );
    },
    meta: {authRequired: true,  title: 'jobs | AI Recruit' },
  },
  {
    path: "/h/billing-page",
    name: "BillingPage",
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "../views/company/billingPage.vue"
      );
    },
    meta: {authRequired: true,  title: 'Billing | AI Recruit' },
  },
  {
    path: "/h/add-card",
    name: "CardPage",
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "../views/company/addCard.vue"
      );
    },
    meta: {authRequired: true, title: 'Add Card | AI Recruit' },
  },
  {
    path: "/h/post-job",
    name: "PostJobPage",
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "../views/company/jobs/post_a_job.vue"
      );
    },
    meta: {authRequired: true, title: 'Post Job | AI Recruit' },
  },
  {
    path: "/h/Post-jobs-details-:id",
    name: "JobsDetailsPage",
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "../views/company/jobs/jobsDetails.vue"
      );
    },
    meta: {authRequired: true,  title: 'Jobs Details | AI Recruit' },
  },
  {
    path: "/h/applications-:id",
    name: "applicationPage",
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "../views/company/jobs/applications.vue"
      );
    },
    meta: {authRequired: true, title: 'Applications | AI Recruit' },
  },
  {
    path: "/h/edit-job",
    name: "EditJobPage",
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "../views/company/jobs/editJob.vue"
      );
    },
    meta: {authRequired: true, title: 'Edit Job | AI Recruit' },
  },
  {
    path: "/h/our-recommendations",
    name: "RecommendationsPage",
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "../views/company/jobs/our_recommendations.vue"
      );
    },
    meta: {authRequired: true, title: 'Recommendations | AI Recruit' },
  },
  {
    path: "/h/hm-profile",
    name: "HmProfile",
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "../views/company/jobs/HM_profile.vue"
      );
    },
    meta: {authRequired: true, title: 'HM Profile | AI Recruit' },
  },

    // LANDING PAGES
  {
    path: "/home",
    name: "homePage",
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "../views/landing/home.vue"
      );
    },
    meta: {title: 'Home | AI Recruit' },
  },
  {
    path: "/about",
    name: "aboutPage",
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "../views/landing/about.vue"
      );
    },
    meta: {title: 'About | AI Recruit' },
  },
  {
    path: "/contact",
    name: "contactPage",
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "../views/landing/contactUs.vue"
      );
    },
    meta: {title: 'Contact | AI Recruit' },
  },

 
  // USER
  {
    path: "/login",
    name: "All Login Options",
    component: function () {
      return import(
        /* webpackChunkName: "about" */ "../views/all_login_options.vue"
      );
    },
  },
  //candidate auth
    {
      path: "/c/login",
      name: "Candidate Login",
      component: function () {
        return import(/* webpackChunkName: "about" */ "../views/candidate/login.vue");
      },
      meta: { title: 'Login | AI Recruit' },
    },
    {
      path: "/c/register",
      name: "Candidate Register",
      component: function () {
        return import(/* webpackChunkName: "about" */ "../views/candidate/register.vue");
      },
      meta: { title: 'Register | AI Recruit' },
    },

    //company auth
    {
      path: "/h/login",
      name: "Company Login",
      component: function () {
        return import(/* webpackChunkName: "about" */ "../views/company/login.vue");
      },
      meta: { title: 'Login | AI Recruit' },
    },
    {
      path: "/h/register",
      name: "Company Register",
      component: function () {
        return import(/* webpackChunkName: "about" */ "../views/company/register.vue");
      },
      meta: { title: 'Register | AI Recruit' },
    },

       //admin auth
       {
        path: "/a/login",
        name: "admin Login",
        component: function () {
          return import(/* webpackChunkName: "about" */ "../views/admin/login.vue");
        },
        meta: { title: 'Login | AI Recruit' },
      },

     // *********404 Page **********
    {
      path: "/:catchAll(.*)",
      name: "Not Found",
      component: () =>
        import(/* webpackChunkName: "about" */ "../components/notFound.vue"),
      meta: { title: '404 Page Not Found' },
    },
    {
      path: "/forgot_password/:id",
      name: "Reset Password",
      component: function () {
        return import(/* webpackChunkName: "about" */ "../views/candidate/resetPassword.vue");
      },
      meta: { title: 'Reset Password | AI Recruit' },
    },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  console.log('from--',from);
  console.log('to--',to);
  var token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));
  // console.log("beforeEach---token--", store);
  // console.log("beforeEach---next--", next);
  // console.log("beforeEach---to--", to);
  // console.log("beforeEach---from--", from);

  var isAuthenticated = Boolean(token);
  var isAuthenticatedUser = Boolean(user);
  document.title = to.meta.title || 'AI Recruit';

  if (to.meta.authRequired) {
    if (isAuthenticated) {
      if (store.state.auth.user == null || store.state.auth.user == undefined) {
        var formData2 = new FormData();
        formData2.append("token", token);
        axios
          .post(axios.defaults.baseURL + "get_current_logged_user", formData2)
          .then((response) => {
            console.log("login details--from routes--", response);
            // store.commit("SET_LOGIN_DETAILS", user);
            // if (response.data.data.email == user.email) {
              const Response = response.data.data
              
              if(Response.roles == 3 || Response.roles == '3'){
                var user = {
                  candidate: Response.candidate[0],
                  name: Response.name,
                  email_verified_at: Response.email_verified_at,
                  roles: Response.roles,
                  id: Response.id,
                  position: Response.position,
                  phone: Response.phone,
                  email: Response.email,
                  company_name: Response.company_name,
                  remember_token: token,
                };
              }
              else if(Response.roles == 2 || Response.roles == '2'){
                var user = {
                  hiring_manager: Response.hiring_manager[0],
                  name: Response.name,
                  email_verified_at: Response.email_verified_at,
                  roles: Response.roles,
                  id: Response.id,
                  position: Response.position,
                  phone: Response.phone,
                  email: Response.email,
                  company_name: Response.company_name,
                  remember_token: token,
                };
              }
              else if(Response.roles == 1 || Response.roles == '1'){
                var user = {
                  name: Response.name,
                  email_verified_at: Response.email_verified_at,
                  roles: Response.roles,
                  id: Response.id,
                  position: Response.position,
                  email: Response.email,
                  company_name: Response.company_name,
                  remember_token: token,
                };
              }
              localStorage.setItem("user", JSON.stringify(user));
              store.commit("SET_LOGIN_DETAILS", user);
              // store.state.Main_Loader_value = false;
              next();
            // } else {
            //   alert("Your login session expired 44");
            //   next({ name: "Login" });
            // }
          })
          .catch((err) => {
            console.log("error hai routes--", err);
            if ( 'message'in err) {
              alert(err.message);
            }
            if (err.response.statusText == "Unauthorized") {
              localStorage.removeItem("token");
              localStorage.removeItem("user");
              
              router.push("/c/login");
            }
          });
      }else{
        next();
      }
    } else {
      // console.log('else-1-next',!to.meta.authRequired,!isAuthenticated);
      next({
        name: "Candidate Login",
      });
    }
  } else if (!to.meta.authRequired && !isAuthenticated) {
    console.log('else-2-next',!to.meta.authRequired, !isAuthenticated);
    next();
  } else {
    console.log('else-3-next',!to.meta.authRequired, !isAuthenticated);
    next();
  }
});

// router.beforeEach((to, from, next) => {
//   // Update the title for each route
//   document.title = to.meta.title || 'AI Recruit';
//   next();
// });

export default router;
