<template>
  <div>
    <header class="main-header">
      <div class="d-flex align-items-center logo-box justify-content-start">
        <!-- Logo -->
        <router-link to="/" class="logo">
          <!-- logo-->
          <div class="logo-mini w-100">
            <!-- <span
              ><img src="/images/AIRecruit_Image.png" alt="logo"
            /></span> -->
            <h2 class="mt-4"><strong>AI Recruit</strong></h2>
            <!-- <span class="dark-logo"
              ><img src="/images/AIRecruit_Image.png" alt="logo"
            /></span> -->
          </div>
          <!-- <div class="logo-lg">
          <span class="light-logo"><img src="/images/METZ Transparent.webp" alt="logo"></span>
          <span class="dark-logo"><img src="/images/METZ Transparent.webp" alt="logo"></span>
        </div> -->
        </router-link>
      </div>
      <!-- Header Navbar -->
      <nav class="navbar navbar-static-top">
        <!-- Sidebar toggle button-->
        <div class="app-menu">
          <ul class="header-megamenu nav align-items-baseline">
            <li class="btn-group nav-item">
              <a
                href="#"
                class="waves-effect waves-light nav-link push-btn btn-primary-light"
                data-toggle="push-menu"
                role="button"
              >
                <i class="fa-solid fa-bars"></i>
              </a>
            </li>
            <strong><h5>{{ User ? User.name : '' }}</h5></strong>
          </ul>
        </div>
        <div class="navbar-custom-menu r-side">
          <ul class="nav navbar-nav">
            <!-- <li class="btn-group nav-item d-xl-inline-flex d-none">
                <a href="#" data-provide="fullscreen"
                  class="waves-effect waves-light nav-link btn-primary-light svg-bt-icon"
                  title="Full Screen">
                  <i data-feather="maximize"></i>
                </a>
              </li> -->

            <!-- User Account -->
            <li class="dropdown user user-menu">
              <a
                href="#"
                class="waves-effect waves-light dropdown-toggle w-auto l-h-12 bg-transparent p-0 no-shadow"
                title="User"
                data-bs-toggle="modal"
                data-bs-target="#quick_user_toggle"
              >
                <img
                v-if="!User || !User.candidate || !User.candidate.profile_picture || User.candidate.profile_picture === 'undefined' || User.candidate.profile_picture === ''"
                  src="/images/avatar/avatar-13.png"
                  class="avatar rounded-circle bg-primary-light h-40 w-40"
                  alt=""
                />
                <img
                  v-else
                  :src="User.candidate.profile_picture"
                  class="avatar rounded-circle bg-primary-light h-40 w-40"
                  alt=""
                />
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  </div>
</template>

<script>
export default {
  name: "HeaderPage",
  props: {
    msg: String,
  },
  
  computed:{
      User(){
        return this.$store.state.auth.user;
      }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
