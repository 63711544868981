export const apis = {

    //JOBS
    GET_JOBS: 'candidate/get_jobs',
    POST_APPLY_JOBS: 'candidate/apply_job',
    GET_APPLYED_JOBS: 'candidate/get_job_application/',
    POST_UPLOAD_RESUME: 'candidate/upload_resume/',

    //PROFILE
    GET_PROFILE_BY_ID: 'candidate/profile/',
    POST_UPDATE_PROFILE_BY_ID: 'candidate/profile/',

    //INTERVIEW
    POST_SCHEDULE_INTERVIEW: 'candidate/schedule_interview',

    //INTERVIEW
    GENERATE_RESPONSIBILITIES: 'candidate/generate_job_responsibilities'

};