import { createStore } from 'vuex';
// import App from "../../src/app.vue";
import candidate_jobs from './module/candidate/jobs.js';
import application_form from './module/candidate/application_form.js';
import jobs from './module/candidate/jobs.js';
import post_job from './module/company/post_job.js';
import application from './module/company/application.js';
import profile from './module/company/profile.js';
import dashboard from './module/company/dashboard.js';
import adminDashboard from './module/admin/adminDashboard.js';
import adminCompanies from './module/admin/adminCompanies.js';
import adminCandidate from './module/admin/adminCandidate.js';
import adminJobs from './module/admin/adminJobs.js';
import adminOutsourcing from './module/admin/adminOutsourcing.js';
import auth from './auth.js';
import VerifyErrorsAnsToasts from './verifyErrorsAnsToasts.js';

export default createStore({
  modules: {
    auth,
    candidate_jobs,
    application_form,
    jobs,
    post_job,
    application,
    profile,
    dashboard,
    adminDashboard,
    adminCompanies,
    adminCandidate,
    adminJobs,
    adminOutsourcing,
    VerifyErrorsAnsToasts,
    // backup
  },
});

// const app = createApp(App);
// app.use(store);
// app.mount('#app');
