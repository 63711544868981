export const apis = {

    //Dashboard
    GET_DASHBOARD: 'admin/dashboard',

    //COMPANIES
    GET_COMPANIES: 'admin/companies',

    //CANDIDATES
    GET_CANDIDATES: 'admin/candidates',

    //OUTSOURCING
    GET_OUTSOURCING: 'admin/outsourcing',
    POST_CONVERT_OUTSOURCING: 'admin/convert_outsourcing/',

    //JOBS
    GET_JOBS: 'admin/jobs',
    GET_JOB_BY_ID: 'admin/get_job/',

};