<template>
  <div>
    <headerr />
    <sidebarr />
    <div class="content-wrapper">
      <div class="container-full">
        <!-- Main content -->
        <pageLoaderr v-if="loader" />
        <!-- <button @click="clickJson()" >clickJson</button> -->
        <section class="content">
          <div class="row">
            <div class="col-xl-12 col-12"></div>
          </div>
          <div class="row">
            <div class="col-xl-6 col-12">
              <div class="box new-box" style="height: 300px">
                <div
                  class="box-body d-flex align-items-center justify-content-center"
                >
                  <div action="">
                    <h2 class="text-h2 pt-5">Upload Your Resume</h2>
                    <input
                      type="file"
                      ref="resumeFile"
                      class="form-control mt-30"
                      @change="uploadResumeFile"
                    />
                    <br />
                    <p v-if="fileError">{{ fileError }}</p>
                    <div class="d-flex justify-content-center">
                      <button class="btn btn-primary" @click="upload()">
                        <Loader v-if="loader" />
                        <span v-if="!loader">Upload Your Resume</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <embed
                :src="previewResumePdf"
                type="video/webm"
                width="100%"
                style="max-height: 70rem; min-height: 20rem"
              />
            </div>

            <div class="col-xl-6 col-12">
              <div class="box new-box" style="height: 300px">
                <div
                  class="box-body d-flex align-items-center justify-content-center"
                >
                  <form action="">
                    <h2 v-if="User && User.candidate && User.candidate.resume != ''" class="text-h2 pt-5">Edit Your Resume</h2>
                    <h2 v-else class="text-h2 pt-5">Manually Fill up</h2>

                    <div class="d-flex justify-content-center mt-40">
                      <RouterLink v-if="User && User.candidate && User.candidate.resume != ''" to="/c/edit-profile">
                        <span class="btn btn-primary">Edit Your Profile</span>
                      </RouterLink>
                      <RouterLink v-else to="/c/application-form">
                        <span class="btn btn-primary">Manually Fill Up</span>
                      </RouterLink>
                    </div>
                  </form>
                </div>
                <!-- {{ content }} -->
              </div>
            </div>
          </div>
        </section>
        <!-- /.content -->
      </div>
    </div>
    <footerr />

    <rightSidebar />
  </div>
</template>

<script>
import headerr from "../../components/candidateLayout/header.vue";
import sidebarr from "../../components/candidateLayout/sidebar.vue";
import footerr from "../../components/candidateLayout/footer.vue";
import rightSidebar from "../../components/candidateLayout/rightSidebar.vue";
import axios from "axios";
import { mapState } from "vuex";
import Loader from "../../components/loader.vue";
import pageLoaderr from '../../components/partials/pageLoader.vue';

// import CountryAndCities from '../../../public/assets/countries_states_cities.json';
// import CountryAndCities from '../../../countries_states_cities.json';

const CountryAndCities = require('../../../usa.json');
// import Docxtemplater from 'docxtemplater';
// import { PDFDocument } from 'pdf-lib';

export default {
  name: "HomePage",
  components: {
    headerr,
    sidebarr,
    footerr,
    rightSidebar,
    Loader,
    pageLoaderr
  },
  data() {
    return {
      fileError: null,
      textResult: "",
      content: "",
      previewResumePdf: "",
      uploadResume_Response: "",
      loader: false,
      //   openaiApiKey: "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",
      // openaiApiKey: "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",
    };
  },
  created() {
    // this.$store.commit("SET_TOTAL_APIS", 8);
    this.$store
      .dispatch("getApplyedJobs",JSON.parse(localStorage.getItem("user")))
      .then((res) => {
        console.log("then geting", res);
      });
  },

  computed: {
    User(){
        return this.$store.state.auth.user;
      },
    ...mapState({
      ApplyedJobs: (state) => state.jobs.applyedJobs,
    }),
  },
  methods: {
    uploadResumeFile(event){
      // const fileInput = this.$refs.resumeFile;
      // const file = fileInput.files[0];
      this.previewResumePdf = URL.createObjectURL(event.target.files[0]);
    },
    upload() {
      console.log("resumeFile", this.$refs.resumeFile);
      const fileInput = this.$refs.resumeFile;
      const file = fileInput.files[0];

      if (!file) {
        this.fileError = "Please select a file.";
        return;
      }
      const allowedExtensions = ["docx", "pdf"];
      const fileExtension = file.name.split(".").pop().toLowerCase();
      console.log("fileExtension--", fileExtension);
      if (!allowedExtensions.includes(fileExtension)) {
        this.fileError =
          "Invalid file format. Please upload a DOCX or PDF file.";
        return;
      }
      this.loader = true;
      this.$store.dispatch("uploadResume",{user: JSON.parse(localStorage.getItem("user")),resume: file}).then((res) => {
        this.loader = false;
        console.log("then uploadResume--", res);
        if(res == null || res == 'null'){
          this.fileError = "Invalid resume response please try again"
        }else{
          this.uploadResume_Response = res;
          this.$router.push('/c/r-application-form')
        }
      }).catch((e) => {
        this.loader = false;
        this.fileError = "Invalid resume response please try again"
        console.log("error e--", e);
      })
    },
    clickJson(){
      // Assume your main JSON data is stored in a variable named mainJsonData
      // const mainJsonData = {
      //   // Your main JSON data here
      // };
      console.log('CountryAndCities-typeof-', typeof CountryAndCities);
      console.log('CountryAndCities-44-', CountryAndCities);
      // console.log('CountryAndCities2--', this.CountryAndCities2);
      // console.log('CountryAndCities2-44-', CountryAndCities2);
      if (!Array.isArray(CountryAndCities)) {
        console.error('CountryAndCities is not an array');
      }
      // return
      // Function to extract required data and save it to a new JSON file
        const newData = []; // This array will store the extracted data

        // Loop through countries in the main JSON
        CountryAndCities.forEach(country => {
          const extractedCountry = {
            name: country.name,
            states: [], // This array will store the extracted states for the current country
          };
        
          // Loop through states in the current country
          for (const state of country.states) {
            const extractedState = {
              name: state.name,
              cities: state.cities.map(city => ({
                name: city.name,
              })),
            };
          
            extractedCountry.states.push(extractedState);
          }
        
          newData.push(extractedCountry);
        })
      
        // Convert the extracted data to JSON format
        const newDataJson = JSON.stringify(newData, null, 2);
      
        // Create a Blob with the JSON data
        const blob = new Blob([newDataJson], { type: 'application/json' });
      
        // Create a download link for the Blob
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'extractedData.json';
      
        // Append the link to the document and trigger a click to download the file
        document.body.appendChild(link);
        link.click();
      
        // Remove the link from the document
        document.body.removeChild(link);
      }

    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
