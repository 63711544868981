export const apis = {

    POST_LOGIN: 'login',
    POST_LOGOUT: 'logout',
    POST_REDISTER_HIRING_MANAGAER: 'hiring_manager/register',
    POST_REDISTER_CANDIATE: 'candidate/register',
    POST_GET_CURRENT_LOGGED_USER: 'get_current_logged_user',
    FORGOT_PASSWORD: 'forgot_password',
    RESET_PASSWORD: 'reset_password',

};