<template>
  <div class="wrapper">
      <router-view />
      <Toast />
    <!-- <router-view />
    <Toast/> -->
  </div>
</template>

<script>
// import { mapState } from "vuex";
import Toast from './components/partials/toast.vue'

export default {
  name: 'App',
  components: {
    Toast
  },
  // computed: {
  //   ...mapState({
  //     User: (state) => state.auth.user,
  //   }),
  //   currentLayout() {
  //     console.log("state --0-- User--", this.User);
  //     if (this.User != null) {
  //       return this.User.roles === 1
  //         ? "AdminLayout"
  //         : this.User.roles === 2
  //         ? "PartnerLayout"
  //         : "UserLayout";
  //     } else {
  //       return "CandidateLayout";
  //     }
  //   },
  // },

}

</script>

<style scoped>
</style>
