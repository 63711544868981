<template>
    <div>
      <aside class="main-sidebar">
        <!-- sidebar-->
        <section class="sidebar position-relative"> 
          <div class="multinav">
          <div class="multinav-scroll" style="height: 97%;">	
            <!-- sidebar menu-->
            <ul class="sidebar-menu" data-widget="tree">					
            <li :class="{ 'active': $route.path === '/' }">
              <router-link to="/">
                <i class="fa-solid fa-house fs-22"></i>
                <span>Dashboard</span>
              </router-link>
            </li>	
    
            <li :class="{ 'active': $route.path === '/c/candidate-jobs' }">
              <router-link to="/c/candidate-jobs">
                <i class="fa-solid fa-folder-plus fs-22"></i>
                <span>Jobs</span>
              </router-link>
            </li>											     
            </ul>
            
          </div>
        </div>
        </section>
      </aside>
    </div>
  </template>
  
  <script>
  export default {
    name: 'sidebarPage',
    props: {
      msg: String
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>

  </style>
  