// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from '../../apis/admin';
// import router from "../router/routes";

const state = {
    // Additional state if needed
    loadingValue: false,
    outsourcingDetails: [],
}

const mutations = {
    UPDATE_LOADING_STATE(state, value) {
        state.loadingValue = value;
    },

    SET_OUTSOURCING_DATA(state, data) {
      state.outsourcingDetails = data
   },

}

const actions = {
    // Additional actions if needed
      getOutsourcing({ commit }, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get(axios.defaults.baseURL + apis.GET_OUTSOURCING, {
                        headers: {
                          Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                      })
                    .then((res) => {
                        console.log('getOutsourcing ress', res)
                        commit('SET_OUTSOURCING_DATA', res.data.data)
                    })
                    .catch((err) => {
                        console.log('getOutsourcing err', err)
                    })
            })
    },

      convertOutsourcing({dispatch, commit }, payload) {
            var formData = new FormData();
            formData.append("job_id", payload.job_id);
            formData.append("amount", payload.amount);

            return new Promise((resolve, reject) => {
                axios
                    .post(axios.defaults.baseURL + apis.POST_CONVERT_OUTSOURCING + payload.job_id, formData, {
                        headers: {
                          Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                      })
                    .then((res) => {
                        console.log('convertOutsourcing ress', res)
                        resolve(res)
                        dispatch("checkErrorAndSendToast", [
                            res,
                            "success",
                        ]);
                        dispatch('getOutsourcing')
                    })
                    .catch((err) => {
                        console.log('convertOutsourcing err', err)
                        reject(err)
                        dispatch("checkErrorAndSendToast", [
                            err.response,
                            "error",
                        ]);
                    })
            })
    },

}

const getters = {
    // Additional getters if needed
}

export default {
    state,
    mutations,
    actions,
    getters,
  };